const init = (elmApp : any) => {
  elmApp.ports.console.subscribe((args : any) => {
    try {
      const [method, ...params] = args;
      // @ts-ignore
      console[method](...params);
    } catch (e) {
      console.error(`port: console: ${e.message}`);
    }
  });
};

export {
  init
};
