// @ts-ignore
import { Elm } from "./Main.elm";
import * as console_ from "./console";
import "strapi-plugin-design/src"

try {
  const elmApp = Elm.Main.init({
    flags: {
      language: navigator.language,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight
      },
    }
  });

  console_.init(elmApp);

} catch (e) {
  console.error(e);
}
