port module Main exposing (main)

import AdditionalText
import Api.Strapi.Query
import Browser exposing (Document, UrlRequest(..))
import Browser.Dom
import Browser.Events
import Browser.Navigation
import Console
import Context exposing (Context, PreContext)
import Effect exposing (Effect)
import Error exposing (Error)
import Flags exposing (Flags)
import Graphql.OptionalArgument as OptionalArgument
import Html exposing (Html)
import Html.Attributes
import Lightbox
import Material.Drawer.Dismissible
import Material.Drawer.Modal
import Material.Elevation
import Material.IconButton
import Material.Snackbar
import Material.TopAppBar
import Maybe.Extra
import Navigation
import Page exposing (Page)
import Ports exposing (Ports)
import Ports.Console
import Result.Extra as Result
import Route
import Screen exposing (Screen)
import Strapi.Client
import Strapi.Footer
import Strapi.Locale
import Strapi.Menu
import Task exposing (Task)
import Task.Extra
import Transition exposing (Transition)
import Ui.Scale
import Url exposing (Url)
import Url.Origin
import UserError exposing (UserError)


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


type Model
    = Crashed (PreContext Msg) ContentErrors Url
    | Loaded (Context Msg) (Transition Content)
    | Loading (PreContext Msg) Url


type alias Content =
    { footer : Strapi.Footer.Data
    , locale : Strapi.Locale.Locale
    , menu : Strapi.Menu.Menu
    , page : Page
    }


type alias ContentErrors =
    { footer : Maybe (Error (Maybe Strapi.Footer.Data))
    , menu : Maybe (Error (Maybe Strapi.Menu.Menu))
    }


type Msg
    = DrawerClosed
    | DrawerToggled
    | Effected Msg
    | LinkClicked UrlRequest
    | LocaleChanged
    | LocaleToggled
    | NavigationChanged Navigation.Msg
    | PageLoaded Strapi.Locale.Locale (Result ContentErrors ( Strapi.Menu.Menu, Strapi.Footer.Data )) ( Page, Effect Msg )
    | PortsChanged (Ports Msg -> Result Ports.Error ( Ports Msg, Cmd Msg ))
    | Resize Screen
    | Scrolled (Result Browser.Dom.Error ())
    | SnackbarClosed Material.Snackbar.MessageId
    | TaskPerformed (Result UserError Msg)
    | UpdateAdditionalText AdditionalText.Msg
    | UpdateLightbox Lightbox.Msg
    | UpdatePage Page.Msg
    | UrlChanged Url


init : Flags -> Url -> Browser.Navigation.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        preContext =
            { additionalText = AdditionalText.init Nothing
            , lightbox = Lightbox.init Nothing
            , locale = Maybe.withDefault Strapi.Locale.en (Strapi.Locale.fromString flags.language)
            , navKey = navKey
            , origin = Url.Origin.fromUrl url
            , ports =
                Ports.init
                    { request = portsRequest
                    , response = portsResponse
                    , setState = PortsChanged
                    }
            , scale = Ui.Scale.Lg
            , snackbarQueue = Material.Snackbar.initialQueue
            }
    in
    ( Loading preContext url
    , loadPage preContext url
    )


loadPage :
    { context
        | locale : Strapi.Locale.Locale
    }
    -> Url
    -> Cmd Msg
loadPage { locale } url =
    Task.map3
        (\menu footer page ->
            PageLoaded locale
                (case ( menu, footer ) of
                    ( Ok okMenu, Ok okFooter ) ->
                        Ok ( okMenu, okFooter )

                    _ ->
                        Err
                            { footer = Result.error footer
                            , menu = Result.error menu
                            }
                )
                page
        )
        (fetchMenu
            { locale = locale
            }
            |> Task.Extra.catch
        )
        (fetchFooter
            { locale = locale
            }
            |> Task.Extra.catch
        )
        (Page.init
            { locale = locale
            , url = url
            }
            url
        )
        |> Task.perform identity


fetchMenu :
    { locale : Strapi.Locale.Locale
    }
    -> Task (Error (Maybe Strapi.Menu.Menu)) Strapi.Menu.Menu
fetchMenu { locale } =
    Api.Strapi.Query.menu
        (\args ->
            { args
                | locale = OptionalArgument.Present locale
            }
        )
        Strapi.Menu.menuSelection
        |> Strapi.Client.makeGraphqlQuery
        |> Task.andThen
            (Maybe.Extra.unwrap
                (Task.fail (Error.custom "required singlet type entity 'Menu' does not exist"))
                Task.succeed
            )


fetchFooter :
    { locale : Strapi.Locale.Locale
    }
    -> Task (Error (Maybe Strapi.Footer.Data)) Strapi.Footer.Data
fetchFooter { locale } =
    Api.Strapi.Query.footer
        (\args ->
            { args
                | locale = OptionalArgument.Present locale
            }
        )
        Strapi.Footer.selectionSet
        |> Strapi.Client.makeGraphqlQuery
        |> Task.andThen
            (Maybe.Extra.unwrap
                (Task.fail (Error.custom "required single type entity 'Footer' does not exist"))
                Task.succeed
            )


getContext : (Context Msg -> a) -> (PreContext Msg -> a) -> Model -> a
getContext f g model =
    case model of
        Crashed preContext _ _ ->
            g preContext

        Loaded context _ ->
            f context

        Loading preContext _ ->
            g preContext


mapContext :
    (Context Msg -> Context Msg)
    -> (PreContext Msg -> PreContext Msg)
    -> Model
    -> Model
mapContext f g model =
    case model of
        Crashed preContext error url ->
            Crashed (g preContext) error url

        Loaded context content ->
            Loaded (f context) content

        Loading preContext url ->
            Loading (g preContext) url


updateContext :
    (Context Msg -> ( Context Msg, cmd ))
    -> (PreContext Msg -> ( PreContext Msg, cmd ))
    -> Model
    -> ( Model, cmd )
updateContext f g model =
    case model of
        Crashed preContext error url ->
            let
                ( newContext, cmd ) =
                    g preContext
            in
            ( Crashed newContext error url, cmd )

        Loaded context content ->
            let
                ( newContext, cmd ) =
                    f context
            in
            ( Loaded newContext content, cmd )

        Loading preContext url ->
            let
                ( newContext, cmd ) =
                    g preContext
            in
            ( Loading newContext url, cmd )


currentUrl : Model -> Url
currentUrl model =
    let
        origin =
            getContext .origin .origin model
    in
    case model of
        Crashed _ _ url ->
            url

        Loaded _ content ->
            Page.toUrl origin (Transition.current content).page

        Loading _ url ->
            url


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ Browser.Events.onResize (\w h -> { height = h, width = w })
            |> Sub.map Resize
        ]


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        navKey =
            getContext .navKey .navKey model
    in
    case ( msg, model ) of
        ( PortsChanged msg_, _ ) ->
            let
                f =
                    Ports.update msg_
            in
            updateContext f f model

        ( _, Crashed _ _ _ ) ->
            ( model, Cmd.none )

        ( Resize screen, _ ) ->
            let
                f preContext =
                    { preContext | scale = Ui.Scale.classify screen }
            in
            ( mapContext f f model, Cmd.none )

        ( UrlChanged newUrl, Loading preContext _ ) ->
            if Route.fromUrl newUrl /= Route.fromUrl (currentUrl model) then
                ( Loading preContext newUrl
                , loadPage preContext newUrl
                )

            else
                ( model, Cmd.none )

        ( UrlChanged newUrl, Loaded context content ) ->
            if Route.fromUrl newUrl /= Route.fromUrl (currentUrl model) then
                Transition.transitionTo content newUrl (loadPage context newUrl)
                    |> Tuple.mapFirst (Loaded context)

            else
                ( Loaded context content, Cmd.none )

        ( DrawerClosed, Loaded context content ) ->
            let
                newContext =
                    { context | drawerOpen = False }
            in
            ( Loaded newContext content, Cmd.none )

        ( DrawerClosed, _ ) ->
            ( model, Cmd.none )

        ( DrawerToggled, Loaded context content ) ->
            let
                newContext =
                    { context | drawerOpen = not context.drawerOpen }
            in
            ( Loaded newContext content, Cmd.none )

        ( DrawerToggled, _ ) ->
            ( model, Cmd.none )

        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                External url ->
                    ( model, Browser.Navigation.load url )

                Internal url ->
                    ( model, Browser.Navigation.pushUrl navKey (Url.toString url) )

        ( NavigationChanged msg_, Loaded context content ) ->
            let
                newNavigation =
                    Navigation.update msg_ context.navigation

                newContext =
                    { context
                        | navigation = newNavigation
                    }
            in
            ( Loaded newContext content, Cmd.none )

        ( NavigationChanged _, _ ) ->
            ( model, Cmd.none )

        ( UpdateAdditionalText msg_, _ ) ->
            let
                f preContext =
                    { preContext
                        | additionalText = AdditionalText.update msg_ preContext.additionalText
                    }
            in
            ( mapContext f f model, Cmd.none )

        ( UpdateLightbox msg_, _ ) ->
            let
                f preContext =
                    { preContext
                        | lightbox = Lightbox.update msg_ preContext.lightbox
                    }
            in
            ( mapContext f f model, Cmd.none )

        ( UpdatePage pageMsg, Loaded context content ) ->
            Transition.update
                (\{ footer, locale, menu, page } ->
                    let
                        ( newPage, effect ) =
                            Page.update context pageMsg page
                    in
                    ( { footer = footer
                      , locale = locale
                      , menu = menu
                      , page = newPage
                      }
                    , effect
                    )
                )
                content
                |> Tuple.mapFirst (Loaded context)
                |> Tuple.mapSecond (Effect.map UpdatePage)
                |> handleEffect

        ( UpdatePage _, _ ) ->
            ( model, Cmd.none )

        ( PageLoaded locale (Ok ( menu, footer )) ( page, effect ), Loading preContext url ) ->
            let
                newModel =
                    Loaded
                        { additionalText = preContext.additionalText
                        , drawerOpen = False
                        , lightbox = preContext.lightbox
                        , locale = preContext.locale
                        , navKey = preContext.navKey
                        , navigation =
                            Navigation.init
                                { currentUrl = url
                                , menu = menu
                                , toUrl = Route.toUrl preContext.origin << Route.fromSlug
                                }
                        , origin = preContext.origin
                        , ports = preContext.ports
                        , scale = preContext.scale
                        , snackbarQueue = preContext.snackbarQueue
                        }
                        (Transition.succeed
                            { footer = footer
                            , locale = locale
                            , menu = menu
                            , page = page
                            }
                        )
            in
            ( newModel, effect )
                |> handleEffect
                |> Tuple.mapSecond
                    (Cmd.batch
                        << (::) (scrollIntoView (currentUrl newModel))
                        << List.singleton
                    )

        ( PageLoaded newLocale (Ok ( menu, footer )) ( page, effect ), Loaded context content ) ->
            let
                locale =
                    (Transition.current content).locale

                newContent =
                    Transition.succeed
                        { footer = footer
                        , locale = newLocale
                        , menu = menu
                        , page = page
                        }

                newModel =
                    Loaded
                        { context
                            | drawerOpen = False
                            , navigation =
                                if newLocale /= locale then
                                    Navigation.init
                                        { currentUrl = Page.toUrl context.origin (Transition.current newContent).page
                                        , menu = menu
                                        , toUrl = Route.toUrl context.origin << Route.fromSlug
                                        }

                                else
                                    context.navigation
                        }
                        newContent
            in
            ( newModel, effect )
                |> handleEffect
                |> Tuple.mapSecond
                    (Cmd.batch
                        << (::) (scrollIntoView (currentUrl newModel))
                        << List.singleton
                    )

        ( PageLoaded _ (Err errors) _, _ ) ->
            let
                f preContext =
                    { additionalText = preContext.additionalText
                    , lightbox = preContext.lightbox
                    , locale = preContext.locale
                    , navKey = preContext.navKey
                    , origin = preContext.origin
                    , ports = preContext.ports
                    , scale = preContext.scale
                    , snackbarQueue = preContext.snackbarQueue
                    }

                ports =
                    getContext .ports .ports model

                newModel =
                    Crashed (getContext f f model) errors (currentUrl model)
            in
            ( newModel
            , Cmd.batch
                [ Ports.Console.error ports (contentErrorsToString errors)
                , scrollIntoView (currentUrl newModel)
                ]
            )

        ( Effected msg_, _ ) ->
            update msg_ model

        ( SnackbarClosed id, _ ) ->
            ( let
                f preContext =
                    { preContext
                        | snackbarQueue =
                            Material.Snackbar.close id preContext.snackbarQueue
                    }
              in
              mapContext f f model
            , Cmd.none
            )

        ( Scrolled _, _ ) ->
            ( model, Cmd.none )

        ( TaskPerformed (Err userError), _ ) ->
            let
                message =
                    Material.Snackbar.message userError.userMessage

                f preContext =
                    { preContext
                        | snackbarQueue =
                            Material.Snackbar.addMessage message preContext.snackbarQueue
                    }
            in
            ( mapContext f f model
            , Ports.Console.error (getContext .ports .ports model)
                userError.errorMessage
            )

        ( TaskPerformed (Ok msg_), _ ) ->
            ( model, Task.perform identity (Task.succeed msg_) )

        ( LocaleToggled, _ ) ->
            let
                f preContext =
                    { preContext
                        | locale =
                            if preContext.locale == Strapi.Locale.deCh then
                                Strapi.Locale.en

                            else
                                Strapi.Locale.deCh
                    }

                g preContext =
                    { locale =
                        if preContext.locale == Strapi.Locale.deCh then
                            Strapi.Locale.en

                        else
                            Strapi.Locale.deCh
                    }
            in
            ( mapContext f f model
            , loadPage (getContext g g model) (currentUrl model)
            )

        ( LocaleChanged, _ ) ->
            ( let
                f preContext =
                    { preContext
                        | locale =
                            if preContext.locale == Strapi.Locale.deCh then
                                Strapi.Locale.en

                            else
                                Strapi.Locale.deCh
                    }
              in
              mapContext f f model
            , Cmd.none
            )


handleEffect : ( Model, Effect Msg ) -> ( Model, Cmd Msg )
handleEffect =
    Effect.handle
        (\model effect ->
            case effect of
                Effect.Batch _ ->
                    -- XXX impossible state
                    ( model, Cmd.none )

                Effect.Cmd cmd ->
                    ( model, cmd )

                Effect.LogError s ->
                    ( model, Console.error s )

                Effect.Navigate route ->
                    let
                        origin =
                            getContext .origin .origin model
                    in
                    ( model
                    , Task.perform LinkClicked
                        (Task.succeed (Browser.Internal (Route.toUrl origin route)))
                    )

                Effect.None ->
                    -- XXX impossible state
                    ( model, Cmd.none )

                Effect.OpenAdditionalText additionalText ->
                    let
                        f preContext =
                            { preContext
                                | additionalText = AdditionalText.init (Just additionalText)
                            }
                    in
                    ( mapContext f f model, Cmd.none )

                Effect.OpenLightbox handle ->
                    let
                        f preContext =
                            { preContext
                                | lightbox = Lightbox.init (Just handle)
                            }
                    in
                    ( mapContext f f model, Cmd.none )

                Effect.ReplaceUrl f ->
                    let
                        navKey =
                            getContext .navKey .navKey model
                    in
                    ( model
                    , Browser.Navigation.replaceUrl navKey
                        (Url.toString (f (currentUrl model)))
                    )

                Effect.Task t ->
                    ( model
                    , Task.attempt TaskPerformed t
                    )
        )


scrollIntoView : Url -> Cmd Msg
scrollIntoView url =
    Task.map2
        (\elementOffset topAppBarHeight ->
            elementOffset - topAppBarHeight
        )
        (url.fragment
            |> Maybe.Extra.unwrap (Task.succeed 0)
                (Task.map (.y << .element) << Browser.Dom.getElement)
        )
        (Browser.Dom.getElement "top-app-bar"
            |> Task.map (.height << .element)
            |> Task.onError (\_ -> Task.succeed 0)
        )
        |> Task.andThen (Browser.Dom.setViewport 0)
        |> Task.attempt Scrolled


contentErrorsToString : ContentErrors -> String
contentErrorsToString { footer, menu } =
    String.join "\n" <|
        Maybe.Extra.toList (Maybe.map Error.toString footer)
            ++ Maybe.Extra.toList (Maybe.map Error.toString menu)



-- View --


view : Model -> Document Msg
view model =
    let
        snackbarQueue =
            getContext .snackbarQueue .snackbarQueue model
    in
    { body =
        [ case model of
            Crashed _ error _ ->
                viewCrashed error

            Loaded context content ->
                viewLoaded context content

            Loading _ _ ->
                viewLoading
        , AdditionalText.view (getContext .additionalText .additionalText model)
            |> Html.map UpdateAdditionalText
        , Lightbox.view (getContext .lightbox .lightbox model)
            |> Html.map UpdateLightbox
        , Material.Snackbar.snackbar
            (Material.Snackbar.config
                { onClosed = SnackbarClosed
                }
            )
            snackbarQueue
        ]
    , title = "miaEngiadina"
    }


viewCrashed : ContentErrors -> Html Msg
viewCrashed _ =
    Html.text "Crashed :-("


viewLoading : Html Msg
viewLoading =
    Html.text "Loading.."


viewLoaded : Context Msg -> Transition Content -> Html Msg
viewLoaded context content =
    let
        drawer =
            Material.Drawer.Modal.drawer
                (Material.Drawer.Modal.config
                    |> Material.Drawer.Modal.setOpen context.drawerOpen
                    |> Material.Drawer.Modal.setOnClose DrawerClosed
                )
                [ Material.Drawer.Modal.content [] <|
                    [ Html.h2 [ Material.TopAppBar.title ]
                        [ Html.text "Menu" ]
                    , let
                        { menu, page } =
                            Transition.current content
                      in
                      Navigation.view
                        { currentUrl = Page.toUrl context.origin page
                        , menu = menu
                        , toUrl = Route.toUrl context.origin << Route.fromSlug
                        }
                        context.navigation
                        |> Html.map NavigationChanged
                    ]
                ]

        isLoading =
            Transition.isTransitioning content
    in
    Html.div
        [ Html.Attributes.classList
            [ ( "app", True )
            , ( "app--loading", Transition.isTransitioning content )
            ]
        ]
        [ drawer
        , Material.Drawer.Modal.scrim [] []
        , Html.div
            [ Html.Attributes.class "app-content"
            , Material.Drawer.Dismissible.appContent
            ]
            [ Html.div [ Html.Attributes.class "header" ]
                [ viewHeader content ]
            , Html.main_
                [ Html.Attributes.classList
                    [ ( "page", True )
                    , ( "page--loading", isLoading )
                    ]
                ]
                (Page.view context (Transition.current content).page)
                |> Html.map UpdatePage
            , Strapi.Footer.view (Transition.current content).footer
            ]
        ]


viewHeader : Transition Content -> Html Msg
viewHeader content =
    Material.TopAppBar.regular
        (Material.TopAppBar.config
            |> Material.TopAppBar.setFixed True
            |> Material.TopAppBar.setAttributes [ Material.Elevation.z3 ]
        )
        [ Material.TopAppBar.row []
            [ Material.TopAppBar.section []
                [ Material.IconButton.iconButton
                    (Material.IconButton.config
                        |> Material.IconButton.setAttributes [ Material.TopAppBar.navigationIcon ]
                        |> Material.IconButton.setOnClick DrawerToggled
                    )
                    (Material.IconButton.icon "menu")
                , Html.h1 [ Material.TopAppBar.title ]
                    [ Html.text (Page.title (Transition.current content).page)
                    ]
                ]
            , Material.TopAppBar.section [ Material.TopAppBar.alignEnd ]
                [ Material.IconButton.iconButton
                    (Material.IconButton.config
                        |> Material.IconButton.setOnClick LocaleToggled
                        |> Material.IconButton.setAttributes
                            [ Material.TopAppBar.actionItem ]
                    )
                    (Material.IconButton.icon "language")
                ]
            ]
        ]


port portsRequest : Ports.ToJs -> Cmd msg


port portsResponse : (Ports.FromJs -> msg) -> Sub msg
